import { Box, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { TicketCount } from './ticket-count';

const useStyles = makeStyles((theme) => ({
  checkIconSuccess: {
    color: theme.palette.success.main,
  },
  checkIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  checkedInText: {
    textDecoration: 'line-through',
  },
  listItem: {
    padding: '1.25rem 0',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  listIcon: {
    justifyContent: 'flex-end',
    minWidth: 0,
  },
  primaryText: {
    fontWeight: 700,
    marginRight: '0.625rem',
  },
}));

interface OrderListItemProps {
  checkedIn?: boolean;
  primaryText: string;
  secondaryText?: string | JSX.Element;
  subtitle?: string;
  handleClick?: () => void;
  ticketCount: number;
  icon?: JSX.Element;
  type?: string;
}

export const OrderListItem = ({
  checkedIn,
  primaryText,
  secondaryText,
  subtitle,
  handleClick,
  ticketCount,
  type,
  icon,
}: OrderListItemProps): ReactElement => {
  const classes = useStyles();

  return (
    <ListItem divider className={classes.listItem} onClick={handleClick}>
      <ListItemText
        primary={
          <Box display='flex' alignItems='center'>
            <TicketCount count={ticketCount} />
            <Typography variant='body2' className={classes.primaryText}>
              <span className={`${checkedIn && classes.checkedInText}`}>{primaryText}</span>
            </Typography>
            {subtitle !== undefined && (
              <Typography variant='body2' color='textSecondary'>
                - <span className={`${checkedIn && classes.checkedInText}`}>{subtitle}</span>
              </Typography>
            )}
          </Box>
        }
        secondary={
          <>
            {secondaryText} {type && `${type}`}
          </>
        }
            ></ListItemText>
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
    </ListItem>
  );
};
