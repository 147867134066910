export const PERSISTED_STORAGE_NAMESPACE = 'mern';

export const ROLE_ADMIN = 'admin';

export const ROLE_USER = 'user';

export const FONT_BOOK = 'Gotham Book, Arial, Helvetica, sans-serif';

export const FONT_BOLD = 'Gotham Bold, Arial, Helvetica, sans-serif';

export enum LocalStorageConf {
  ApiUrl = 'apiUrl',
  TokenV1 = 'tokenV1',
  TokenV2 = 'tokenV2',
}

export enum OrderMessage {
  EntireOrderChecked = 'Order successfully scanned',
}

export enum UserMessages {
  GenericLoginError = 'Authentication Failed',
}
export enum TicketMessages {
  AnotherEventError = 'Ticket is for another event',
  AnotherTimeSlotError = 'Ticket is for another time slot',
  AlreadyScannedError = 'Ticket already scanned',
  UnknownError = 'Unknown Error',
  TicketNotFound = 'Ticket not found',
  InvalidTicketType = 'Invalid ticket type',
}

export enum GenericMessage {
  UnknownError = 'Unknown Error',
}

export const TICKETS_LIMIT = 300;
export const PENDING_TICKETS_LIMIT = 20;
