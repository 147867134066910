import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, List, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import { EventTitle } from '../display/event-title';
import { EventCalendar } from '../display/event-calendar';
import { ProcessState } from '../../model/tsp/order';
import { countValidTickets } from '../../util/order-util';
import { LoaderModal } from '../display/loader-modal';
import { FONT_BOLD } from '../../model/constants';
import { fixDateIfApply } from '../../util/date-utils';
import { useAppSelector } from '../../store';
import OrderTicket from '../display/order-ticket';
import { Ticket } from '../../model/tsp/ticket';
import { QuestionsView } from '../display/questions';
import { cleanCheckin } from '../../store/slices/ticket-slice';
import { useDispatch } from 'react-redux';
import { selectIsMultipleEvents, selectSelectedEvent } from '../../store/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    marginTop: '1.25rem',
    marginBottom: '0.625rem',
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.error.main,
    },
  },
  detailsButton: {
    marginTop: '1.25rem',
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
  },
  openButton: {
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.info.main,
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  checkIcon: {
    width: '3.875rem',
    height: '3.875rem',
    color: theme.palette.success.main,
    marginBottom: '1.25rem',
  },
  removeIcon: {
    width: '3.875rem',
    height: '3.875rem',
    color: theme.palette.warning.main,
    marginBottom: '1.25rem',
  },
  checkInMessage: {
    paddingTop: '1rem',
  },
}));

export const OrderDetailPage: FC = () => {
  const history = useHistory<{ attendeePage: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const order = useAppSelector((state) => state.order.selectedOrder);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const { loading, undoLoading, errorMessage } = useAppSelector((state) => state.ticket);
  const { barCode } = useParams<{ barCode: string }>();

  let orderTicket = order.tickets.data.find((ot) => ot.barcode === barCode);
  if (!orderTicket) {
    orderTicket = order.tickets.data[0];
  }

  const fromAttendeePage = history.location.state?.attendeePage ? true : false;

  const purchaserTicket: Ticket = order.tickets.data.find(
    (ot) => ot.partyMember === ot.billing_firstName && ot.partyMemberLastName === ot.billing_lastName,
  );

  useEffect(() => {
    if (countValidTickets(order) > 1) {
      setShowDetails(true);
    }

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  if (!order) {
    return <Redirect to='/' />;
  }

  return (
    <>
      {selectedEvent && order !== null && orderTicket !== null && (
        <Box padding='1.25rem'>
          <Box marginBottom='1.25rem' onClick={() => history.goBack()}>
            <Grid container alignItems='center'>
              <Grid item xs={1}>
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </Grid>
              <Grid item xs={4}>
                <EventCalendar weekday={true} date={fixDateIfApply(new Date(selectedEvent.start * 1000))} />
              </Grid>
              <Grid item xs={7}>
                <EventTitle isMultipleEvents={isMultipleEvents} event={selectedEvent} />
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box paddingTop='1.25rem' paddingBottom='1.25rem'>
            <Box textAlign='center'>
              {fromAttendeePage ? (
                <>
                  <Typography variant='h4'>
                    {orderTicket.partyMember} {orderTicket.partyMemberLastName}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant='h4'>Success!</Typography>
                  <Typography variant='h6' className={classes.checkInMessage}>
                    {orderTicket.partyMember} {orderTicket.partyMemberLastName} is checked in!
                  </Typography>
                  <Typography>
                    {orderTicket.ticketTypeName} - {orderTicket.barcode}
                  </Typography>
                </>
              )}

              {errorMessage !== null && <Typography color='error'>{errorMessage}</Typography>}
            </Box>

            <Button
              variant='contained'
              color='primary'
              className={classes.closeButton}
              component={Link}
              to={`/event/${selectedEvent.id}`}
              fullWidth
            >
              {fromAttendeePage ? 'Back To Event' : 'Close Scanner'}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={classes.openButton}
              onClick={() => history.goBack()}
              fullWidth
            >
              {fromAttendeePage ? 'Back to Attendee Search' : 'Continue Scanning'}
            </Button>

            {showDetails && (
              <Box paddingTop='1.25rem'>
                <Divider />
                <List>
                  {order.tickets.data
                    .filter((ot) => ot.processState !== ProcessState.Cancelled)
                    .map((orderTicket, idx) => (
                      <OrderTicket key={idx} orderTicket={orderTicket} isOpen={orderTicket.barcode === barCode} />
                    ))}
                </List>

                {purchaserTicket?.purchaserQuestions && (
                  <QuestionsView title='Purchaser Questions' questions={purchaserTicket.purchaserQuestions} />
                )}
              </Box>
            )}

            <Button
              variant='outlined'
              onClick={() => setShowDetails(!showDetails)}
              fullWidth
              className={classes.detailsButton}
            >
              <InfoIcon fontSize='small' className={classes.infoIcon} />
              {showDetails ? 'Hide Order Details' : 'Show Order Details'}
            </Button>
          </Box>
        </Box>
      )}

      <LoaderModal loading={loading} text={'Checking In...'} />
      <LoaderModal loading={undoLoading} text={'Undo Checking In...'} />
    </>
  );
};
