import { ReducedTicketType } from './../../model/tsp/ticket-type';
import {
  checkinTicket,
  retrievePendingTickets,
  retrieveTicketsByEventId,
  retrieveTicketsTypes,
  syncPendingTicket,
  syncProcess,
  syncTicketsFromServer,
  undoCheckinTicket,
} from '../thunks/ticket-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckinResponse } from '../../model/tsp/checkin';

import { PendingTicket } from '../../service/db-service';
import { ProcessState } from '../../model/tsp/order';
import { EventTicketType } from '../../model/tsp/ticket-type';

export interface TicketState {
  loading: boolean;
  undoLoading: boolean;
  checkin: CheckinResponse | null;
  loadingTickets: boolean;
  ticketsCount: {
    [eventId: string]: {
      total: number;
      checkin: number;
    };
  };
  ticketTypes: EventTicketType;
  selectedTicketTypes: EventTicketType;
  pendingTickets: PendingTicket[];
  errorMessage: string | null;
  syncProcess: boolean;
  syncProcessStart: Date | null;
  lastUpdate: number;
}

const initialState: TicketState = {
  loading: false,
  undoLoading: false,
  checkin: null,
  loadingTickets: false,
  ticketsCount: {},
  ticketTypes: {},
  selectedTicketTypes: {},
  pendingTickets: [],
  errorMessage: null,
  syncProcess: false,
  syncProcessStart: null,
  lastUpdate: 0,
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    cleanCheckin: (state) => {
      state.checkin = null;
      state.errorMessage = null;
    },
    setErrorMessage: (state, { payload }: PayloadAction<string | null>) => {
      state.errorMessage = payload;
    },
    updatePendingState: (state, { payload }: PayloadAction<PendingTicket>) => {
      state.pendingTickets = state.pendingTickets.map((p) => (p.id === payload.id ? payload : p));
    },
    removePendingState: (state, { payload }: PayloadAction<PendingTicket>) => {
      state.pendingTickets = state.pendingTickets.filter((p) => p.id !== payload.id);
    },
    setSyncProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.syncProcess = payload;
    },
    setLastUpdate: (state, { payload }: PayloadAction<number>) => {
      state.lastUpdate = payload;
    },
    cleanPendingTickets: (state) => {
      state.pendingTickets = [];
    },
    selectTicketType: (state, { payload }: PayloadAction<{ eventId: string; ticketType: ReducedTicketType }>) => {
      const ticketTypes = (state.selectedTicketTypes[payload.eventId] || []) as ReducedTicketType[];
      const filteredTypes = ticketTypes.filter((selectedType) => selectedType.id !== payload.ticketType.id);
      if (filteredTypes.length === ticketTypes.length) {
        filteredTypes.push(payload.ticketType);
      }
      state.selectedTicketTypes[payload.eventId] = filteredTypes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkinTicket.pending, (state) => {
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(checkinTicket.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.checkin = payload;
      console.log(payload);
      if (!payload.valid) {
        state.errorMessage = payload.reason;
      }
    });
    builder.addCase(checkinTicket.rejected, (state, { payload }) => {
      state.loading = false;
      state.checkin = null;
      state.errorMessage = payload?.message || 'Check-in failed';
    });
    builder.addCase(undoCheckinTicket.pending, (state) => {
      state.undoLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(undoCheckinTicket.fulfilled, (state, { payload }) => {
      state.undoLoading = false;
      state.checkin = null;
    });
    builder.addCase(undoCheckinTicket.rejected, (state, { payload }) => {
      state.undoLoading = false;
      state.errorMessage = payload?.message || 'Undo check-in failed';
    });
    builder.addCase(retrieveTicketsByEventId.pending, (state, action) => {
      state.loadingTickets = true;
      state.errorMessage = null;
      const prev = state.ticketsCount[action?.meta?.arg];
      if (!prev && action?.meta?.arg) {
        state.ticketsCount[action.meta.arg] = {
          total: 0,
          checkin: 0,
        };
      }
    });
    builder.addCase(retrieveTicketsByEventId.fulfilled, (state, { payload }) => {
      state.loadingTickets = false;
      state.ticketsCount[payload?.eventId] = {
        total: payload.res?.totalCount || 0,
        checkin: payload?.res?.data?.filter((d) => d.processState === ProcessState.CheckedIn).length || 0,
      };
      state.errorMessage = null;
    });
    builder.addCase(retrieveTicketsByEventId.rejected, (state, { payload }) => {
      state.loadingTickets = false;
      if (payload?.eventId) {
        state.ticketsCount[payload.eventId] = {
          total: 0,
          checkin: 0,
        };
      }
      state.errorMessage = payload?.message || null;
    });
    builder.addCase(retrievePendingTickets.pending, (state) => {
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(retrievePendingTickets.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pendingTickets = [...state.pendingTickets, ...payload];
    });
    builder.addCase(retrievePendingTickets.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || null;
    });
    builder.addCase(syncTicketsFromServer.fulfilled, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncTicketsFromServer.rejected, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncProcess.fulfilled, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncProcess.rejected, (state) => {
      state.syncProcess = false;
    });
    builder.addCase(syncPendingTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(syncPendingTicket.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(syncPendingTicket.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(retrieveTicketsTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(retrieveTicketsTypes.fulfilled, (state, { payload }: PayloadAction<EventTicketType>) => {
      state.loading = false;
      const eventId = Object.keys(payload)[0];
      state.ticketTypes[eventId] = payload[eventId];
    });
    builder.addCase(retrieveTicketsTypes.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ticketSlice.reducer;

// ------------ ACTIONS ------------

export const {
  cleanCheckin,
  setErrorMessage,
  removePendingState,
  updatePendingState,
  setSyncProcess,
  setLastUpdate,
  cleanPendingTickets,
  selectTicketType,
} = ticketSlice.actions;
