import { db, ordersDBService, ticketsDBService, KeyIndex } from '.';
import { Order } from '../../model/tsp/order';
import { Ticket } from '../../model/tsp/ticket';
import { useAppSelector } from '../../store';
import { selectSelectedTicketTypes } from '../../store/slices/selectors';

type QueryKey =
  | KeyIndex.PARTY_FIRST_NAME_INDEX
  | KeyIndex.PARTY_LAST_NAME_INDEX
  | KeyIndex.FULL_NAME_INDEX
  | KeyIndex.BARCODE_INDEX
  | KeyIndex.GROUP_INDEX
  | KeyIndex.EMAIL_INDEX;

export class AttendeesService {
  async getAttendees(eventIds: string[], key: QueryKey, value: string[], limit?: number, ticketTypeIds?: string[]): Promise<Order[]> {
    const tickts = (await db.transaction('r', db.tickets, async () => {
      return await db.tickets
        .where(key)
        .startsWithAnyOfIgnoreCase(value)
        .limit(limit || 10)
        .toArray();
    })) as Ticket[];
    return ordersDBService.transformTicketsToOrders(tickts);
  }

  async findAttendee(eventIds: string[], text: string, ticketTypeIds?: string[]): Promise<Order[]> {
    const searchWord = text.trim().split(' ');
    let orders: Order[] = [];
    const limit = text.trim() === '' ? 10 : null;
    const tickets = await this.getAttendees(eventIds, KeyIndex.FULL_NAME_INDEX, searchWord, limit, ticketTypeIds);
    if (tickets.length > 0) {
      orders = [...orders, ...tickets];
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.EMAIL_INDEX, searchWord, limit, ticketTypeIds);
    if (orders.length > 0) {
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.GROUP_INDEX, searchWord, 1, ticketTypeIds);
    if (orders.length > 0) {
      return orders;
    }
    orders = await this.getAttendees(eventIds, KeyIndex.BARCODE_INDEX, searchWord, 1, ticketTypeIds);
    return orders;
  }
}
