import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../../model/common-types';
import { LocalStorageConf, UserMessages } from '../../model/constants';
import { LoginReq } from '../../model/tsp/user';
import { apiLogin, apiLoginV2 } from '../../service/api-service';
import { loadSettings } from './settings-thunks';

type UserLoginRes = { userName: string; accessToken: string; apiUrl: string };
export const userLogin = createAsyncThunk<UserLoginRes, LoginReq, { rejectValue: ApiError }>(
  'userLogin',
  async (userLogin, thunkApi) => {
    const { userName, password, apiUrl } = userLogin;
    if (!apiUrl || apiUrl === '' || !userName || userName === '' || !password || password === '') {
      return thunkApi.rejectWithValue({ code: 400, message: UserMessages.GenericLoginError });
    }

    localStorage.setItem(LocalStorageConf.ApiUrl, apiUrl);

    try {
      const res = await apiLogin(userLogin);
      const res2 = await apiLoginV2({ login: userLogin.userName, password: userLogin.password, apiUrl: userLogin.apiUrl });

      if (res.success) {
        localStorage.setItem(LocalStorageConf.TokenV1, res.data.jwt);
        thunkApi.dispatch(loadSettings());
      }
      if (res2.jwt) {
        localStorage.setItem(LocalStorageConf.TokenV2, res2.jwt);
      }

      return { userName: userLogin.userName, accessToken: res.data.jwt, apiUrl: userLogin.apiUrl };
    } catch (error) {
      return thunkApi.rejectWithValue({ code: 400, message: UserMessages.GenericLoginError } as ApiError);
    }
  },
);
