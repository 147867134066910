import { RootState } from '.';
import { Event } from '../model/tsp/event';
import { SettingsState } from './slices/settings-slice';

export const selectSelectedEvent = ({ event: { isMultipleEvents, selectedEvent, toScan } }: RootState): Event => {
  if (isMultipleEvents) {
    return selectedEvent || toScan?.[0] || null;
  } else {
    return toScan?.[0] || null;
  }
};

export const selectIsMultipleEvents = (state: RootState): boolean => state.event.isMultipleEvents;

export const selectEventsToScan = (state: RootState): Event[] => state.event.toScan;

export const selectEventsToScanIds = (state: RootState): string[] => state.event.toScan.map((event) => event.id);

export const selectIsOffline = (state: RootState): boolean => (state.settings as SettingsState).offlineMode;

export const selectOnlineModal = (state: RootState): boolean => (state.settings as SettingsState).onlineModal;

export const selectLoadingSyncToOnline = (state: RootState): boolean =>
  (state.settings as SettingsState).loadingSyncToOnline;

export const selectIsOrdersLoading = (state: RootState): boolean => state.order.loading;
export const selectOpenModal = (state: RootState): string => state.modalConfirmation.modalType;
export const selectIsOpenModal = (state: RootState): boolean => state.modalConfirmation.isOpened;
export const selectModalArgs = (state: RootState): string[] => state.modalConfirmation.args;
export const selectModalLoading = (state: RootState): boolean => state.modalConfirmation.loading;
export const selectTicketType = (state: RootState): string => state.event.ticketType;