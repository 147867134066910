import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, Modal, Slider, Theme, Typography, withStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { QRCode } from 'jsqr';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FONT_BOLD } from '../../model/constants';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { fixDateIfApply } from '../../util/date-utils';
import { EventCalendar } from '../display/event-calendar';
import { LoaderModal } from '../display/loader-modal';
import { TicketScanner } from '../display/ticket-scanner';
import { selectEvent } from '../../store/slices/event-slice';
import { useAppSelector } from '../../store';
import { cleanCheckin, setErrorMessage } from '../../store/slices/ticket-slice';
import { checkinTicket } from '../../store/thunks/ticket-thunks';
import { PlayFunction } from 'use-sound/dist/types';
import { selectEventsToScan, selectSelectedEvent } from '../../store/selectors';
import SuccessNotification from '../display/success-notification';
import { MAX_TICKETS_ON_WHITELIST } from '../../util/constants';
import { selectSelectedTicketTypes } from '../../store/slices/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: '1.25rem',
    marginBottom: '0.625rem',
    fontFamily: FONT_BOLD,
  },
  openButton: {
    backgroundColor: theme.palette.info.main,
  },
  controlBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    position: 'absolute',
    padding: '1.25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    width: '100%',
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  arrowIconWhite: {
    color: '#ffffff',
    fontSize: '0.9375rem',
  },
  modalBox: {
    width: '100%',
    maxWidth: '25rem',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    'border-radius': '10px',
  },
  modalPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  modalTicket: {
    position: 'absolute',
    width: '100%',
  },
}));

const ZoomSlider = withStyles({
  root: {
    color: '#0A84FF',
    height: 2,
    margin: '0 0.5rem',
  },
  thumb: {
    width: 28,
    height: 28,
    backgroundColor: '#ffffff',
    marginTop: -14,
    marginLeft: -8,
  },
})(Slider);

interface ScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const ScanPage: FC<ScanPageProps> = ({ playError, playSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedEvent = useAppSelector(selectSelectedEvent);
  const { checkin, errorMessage, loading } = useAppSelector((state) => state.ticket);
  const toScan = useAppSelector(selectEventsToScan);

  const [videoHeight, setVideoHeight] = useState<number>(100);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [recentScan, setRecentScan] = useState<string[]>([]);
  const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);
  const apiLoading = useRef<boolean>(false);

  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    dispatch(selectEvent(eventId));
  }, [eventId]);

  useEffect(() => {
    dispatch(cleanCheckin());

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  const checkinScan = useCallback(async (barCode: string) => {
    const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);
    const query = {
      barCode,
      eventIds: toScan.map((event) => event.id),
      occurrence: null,
      ticketTypeIds: selectedTicketTypes,
    };

    if (barCode.length > 0 && apiLoading.current === false && !errorMessage) {
      apiLoading.current = true;
      setOverrideModalOpen(false);

      dispatch(checkinTicket(query));
    }
  }, []);

  const onScan = useCallback(
    (code: QRCode): void => {
      const barCode = code.data;
      const isRecent = recentScan.find(code => code === barCode);
      if (barCode.length > 0 && !isRecent) {
        checkinScan(barCode);
      }
    },
    [checkinScan, recentScan],
  );

  const onMediaError = (mediaError: string | DOMException) => dispatch(setErrorMessage(mediaError?.toString()));

  const handleSlider = (event: any, value: number | number[]) => {
    setVideoHeight((value as number) + 100);
  };

  if (errorMessage) {
    playError();
  }

  const goToScannedEvent = () => {
    history.push(`/event/${eventId}/order/${checkin.orderId}/barcode/${checkin.barcode}`);
  };

  const updateWhitelist = (barcode: string) => {
    const isFullWhitelist = recentScan.length >= MAX_TICKETS_ON_WHITELIST;
    if (isFullWhitelist) {
      const lastTickets = recentScan.slice(-MAX_TICKETS_ON_WHITELIST);
      return setRecentScan([...lastTickets, barcode])
    }
    return setRecentScan([...recentScan, barcode])
  }

  useEffect(() => {
    if (checkin?.valid) {
      setSuccessNotification(true);
      updateWhitelist(checkin.barcode);
      playSuccess();
      apiLoading.current = false;
      setTimeout(() => {
        setSuccessNotification(false);
      }, 5000);
    }
  }, [checkin]);

  return selectedEvent !== null ? (
    <>
      <Box height={'calc(100vh - 56px)'} width='100vw' overflow='hidden' display='flex' justifyContent='center'>
        <TicketScanner onMediaError={onMediaError} onScan={onScan} videoHeight={videoHeight ? videoHeight : 100} />

        <Box className={classes.controlBox}>
          <Box width='100%'>
            <Link to={`/event/${eventId}`}>
              <Grid container alignItems='center'>
                <Grid item xs={1}>
                  <ArrowBackIosIcon className={classes.arrowIconWhite} />
                </Grid>
                <Grid item xs={2}>
                  <EventCalendar weekday={false} date={fixDateIfApply(new Date(selectedEvent.start * 1000))} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='h6'>{selectedEvent.title}</Typography>
                  <Typography variant='subtitle2'>Check-In</Typography>
                </Grid>
              </Grid>
            </Link>
          </Box>
        </Box>
        {!loading && successNotification && (
          <Box bottom='11rem' className={classes.modalTicket}>
            <SuccessNotification goToScannedEvent={goToScannedEvent} label='Ticket Checked In' />
          </Box>
        )}
        <Box height='178px' bottom='0' className={classes.controlBox}>
          <ZoomSlider value={videoHeight - 100} onChange={handleSlider} />
        </Box>
      </Box>
      <LoaderModal loading={loading} />
      <Modal
        open={errorMessage !== null}
        onClose={() => dispatch(setErrorMessage(null))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={classes.modalPosition} p='3.25rem'>
          <Box className={classes.modalBox} px='1.25rem' py='1.875rem'>
            <Box mb='1.5rem'>
              <Typography align='center'>{errorMessage}</Typography>
            </Box>
            <Button
              className={classes.closeButton}
              fullWidth
              onClick={() => {
                dispatch(setErrorMessage(null));
                apiLoading.current = false;
              }}
              variant='contained'
            >
              Dismiss
            </Button>
          </Box>
        </Box>
      </Modal>
      <CheckInOverrideModal
        open={overrideModalOpen}
        closeModal={() => {
          setOverrideModalOpen(false);
          apiLoading.current = false;
        }}
      />
    </>
  ) : null;
};
