import { AttendeesService } from './attendees-db-service';
import { CheckinsService } from './checkins-db-service';
import { OrdersService } from './orders-db-service';
import { TicketsService } from './tickets-db-service';
import { Event } from '../../model/tsp/event';
import { Ticket } from '../../model/tsp/ticket';
import Dexie, { Table } from 'dexie';
import { ReducedTicketType } from '../../model/tsp/ticket-type';

const DB_NAME = 'us-premier';

export enum KeyIndex {
  BARCODE_INDEX = 'barcode',
  PROCESS_STATE_INDEX = 'processState',
  GROUP_INDEX = 'group',
  PARTY_FIRST_NAME_INDEX = 'partyMember',
  PARTY_LAST_NAME_INDEX = 'partyMemberLastName',
  PARTY_INDEX = `[partyMember+partyMemberLastName]`,
  FULL_NAME_INDEX = 'fullName',
  EMAIL_INDEX = 'email',
}

export interface OfflineMode {
  id: string;
  event: Event;
  ticketsCount?: number;
}

export enum PendingTicketState {
  PENDING = 'pending',
  ERROR = 'error',
  SYNCED = 'synced',
}

export interface PendingTicket {
  ticket: Ticket;
  guid: string;
  id: string;
  state: PendingTicketState;
  usedScans: string;
  message?: string;
  code?: number;
}

export class DbService extends Dexie {
  offlineMode!: Table<OfflineMode, string>;
  tickets!: Table<Ticket, string>;
  pendingCheckin!: Table<PendingTicket, string>;
  offlineTicketTypes!: Table<ReducedTicketType, string>;

  constructor() {
    super(DB_NAME);
    this.version(4).stores({
      offlineMode: '++id',
      tickets: `++id,barcode,group,processState,partyMember,partyMemberLastName,eventId,typeId`,
      pendingCheckin: '++guid,id,state',
      offlineTicketTypes: '++id,eventId',
    });
  }

  initializeOffline(event: Event) {
    return this.transaction('rw', this.offlineMode, async () => {
      return await this.offlineMode.add({ id: event.id, event });
    });
  }

  getOfflineEvent(): Promise<OfflineMode[]> {
    return this.transaction('r', this.offlineMode, async () => {
      return await this.offlineMode.toArray();
    });
  }

  resetDatabase() {
    return db.transaction('rw', db.tickets, db.offlineMode, db.offlineTicketTypes, db.pendingCheckin, async () => {
      await Promise.all([db.tickets.clear(), db.offlineMode.clear(), db.offlineTicketTypes.clear()]);
    });
  }
}

export const db = new DbService();
export const ordersDBService = new OrdersService();
export const ticketsDBService = new TicketsService();
export const checkinsDBService = new CheckinsService();
export const attendeesDBService = new AttendeesService();
