import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { TicketIcon, LaserScanIcon } from '../../assets';
// import { ArrowRightBlueIcon } from '../../assets';
import { EventCalendar } from '../display/event-calendar';
import { EventTitle } from '../display/event-title';
import { FONT_BOLD } from '../../model/constants';
import { fixDateIfApply } from '../../util/date-utils';
import { selectEvent } from '../../store/slices/event-slice';
import { useAppSelector } from '../../store';
import { retrieveTicketsByEventId } from '../../store/thunks/ticket-thunks';
import OfflineButtons from '../display/offline/offline-buttons';
import { existsPendingsWithErrors } from '../../store/thunks/settings-thunks';
import {
  selectEventsToScan,
  selectIsMultipleEvents,
  selectSelectedEvent,
  selectOnlineModal,
} from '../../store/selectors';
import OnlineModal from '../display/online-modal';
import { selectSelectedTicketTypes } from '../../store/slices/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  checkinContainer: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  checkinBtn: {
    textTransform: 'uppercase',
    color: '#ffffff',
    backgroundColor: theme.palette.success.main,
    fontSize: '12px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  searchBtn: {
    marginTop: '1.25rem',
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: '#ffffff',
      backgroundColor: theme.palette.info.main,
      boxShadow: 'none',
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  marginTop: {
    marginTop: '1.25rem',
  },
  attendeesContainer: {
    display: 'flex',
    marginTop: '4px',
    justifyContent: 'space-between',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '110%',
  },
  attendeesTotal: {
    display: 'flex',
  },
  attendeesTotalCount: {
    marginRight: '3px',
    fontWeight: 'bold',
  },
  attendeesTotalCheckinCount: {
    color: theme.palette.grey[600],
  },
  ticketsTypesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ticketsTypesFont: {
    color: 'rgba(40, 118, 201, 1)',
    fontFamily: FONT_BOLD,
  },
  subtitle: {
    marginBottom: '0.75rem',
  },
  eventList: {
    marginBottom: '0.5rem',
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
  },
  selectTicketType: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#ffffff',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

export const EventDetailPage: FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const toScan = useAppSelector(selectEventsToScan);
  const ticketsCount = useAppSelector((state) => state.ticket.ticketsCount);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);
  const selectedTicketTypes = eventSelectedTicketTypes[id];
  const onlineModal = useAppSelector(selectOnlineModal);
  const { loading, pendingTicketsCount } = useAppSelector((state) => state.settings);
  // const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);
  // const selectedTicketTypes = (eventSelectedTicketTypes[id] || []).map((selected) => selected.id);

  const totalTicketsCheckin = ticketsCount?.[id]?.checkin || 0;
  const totalTickets = ticketsCount?.[id]?.total || 0;
  const history = useHistory();

  const [eventDate, setDate] = React.useState<Date | null>(null);

  useEffect(() => {
    if (!isMultipleEvents) {
      dispatch(selectEvent(id));
      dispatch(retrieveTicketsByEventId(id));
    }
    dispatch(existsPendingsWithErrors());
  }, []);

  useEffect(() => {
    if (selectedEvent !== null) {
      setDate(new Date(selectedEvent.start * 1000));
    }
  }, [selectedEvent]);

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      {selectedEvent && (
        <>
          <Box margin='0 1.125rem' paddingTop='1.5rem'>
            <Link to='/'>
              <Grid container alignItems='center'>
                <Grid item xs={1} md={2}>
                  <Box>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </Box>
                </Grid>
                {eventDate !== null && (
                  <Grid item xs={4} md={2}>
                    <EventCalendar weekday={true} date={fixDateIfApply(eventDate)} />
                  </Grid>
                )}
                <Grid item xs={7} md={8}>
                  <EventTitle event={selectedEvent} isMultipleEvents={isMultipleEvents} />
                </Grid>
              </Grid>
            </Link>
            <Divider className={classes.marginTop} />
            {!isMultipleEvents && (
              <>
                <Box mt={2} onClick={() => history.push(`/event/${id}/ticket-types`)} className={classes.selectTicketType}>
                  {selectedTicketTypes && selectedTicketTypes.length > 0 ? (
                    <Box className={classes.ticketsTypesContainer}>
                      {selectedTicketTypes.map((ticketType, index) => (
                        <Typography key={ticketType.id} className={classes.ticketsTypesFont}>
                          {ticketType.name}{index !== selectedTicketTypes.length - 1 ? ',' : ''}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    <Box>All Ticket Types</Box>
                  
                  )}
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </Box>
                <Divider className={classes.marginTop} />
              </>
            )}
            {!isMultipleEvents && (
              <Box mt={2}>
                <BorderLinearProgress variant='determinate' value={(totalTicketsCheckin / totalTickets) * 100} />
                <Box className={classes.attendeesContainer}>
                  <Box>Attendees</Box>
                  <Box className={classes.attendeesTotal}>
                    <Box className={classes.attendeesTotalCount}>{totalTicketsCheckin}</Box>
                    <Box className={classes.attendeesTotalCheckinCount}>/ {totalTickets}</Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Button
              variant='contained'
              color='primary'
              size='medium'
              type='submit'
              fullWidth
              className={classes.searchBtn}
              component={Link}
              to={`/attendees/${id}`}
            >
              View / Search Attendees
            </Button>
            {loading && (
              <Box marginTop={2}>
                <LinearProgress color='secondary' />
              </Box>
            )}
            <Divider className={classes.marginTop} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  className={classes.checkinContainer}
                  textAlign='center'
                  padding='0.625rem 0'
                  paddingBottom='0'
                  margin='1.25rem 0'
                >
                  <Link to={`/scan/${id}`}>
                    <TicketIcon />
                    <Box className={classes.checkinBtn} marginTop='0.625rem' padding='0.5rem 0'>
                      <Typography variant='subtitle1'>Begin Camera Scanning</Typography>
                    </Box>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  className={classes.checkinContainer}
                  textAlign='center'
                  padding='0.625rem 0'
                  paddingBottom='0'
                  margin='1.25rem 0'
                >
                  <Link to={`/laser-scan/${id}`}>
                    <LaserScanIcon />
                    <Box className={classes.checkinBtn} marginTop='0.625rem' padding='0.5rem 0'>
                      <Typography variant='subtitle1'>Begin Laser Scanning</Typography>
                    </Box>
                  </Link>
                </Box>
              </Grid>
            </Grid>
            {/* <Box margin='0 1.125rem' paddingTop='1.5rem'>
              <Grid item xs={12}>
                <Link to={`/event/${id}/ticket-types`}>
                  <Box className={classes.ticketsTypesContainer}>
                    <Typography className={classes.ticketsTypesFont}>
                      Manage Tickets to scan ({selectedTicketTypes?.length > 0 ? selectedTicketTypes.length : 'All'})
                    </Typography>
                    <ArrowRightBlueIcon />
                  </Box>
                </Link>
              </Grid>
            </Box> */}
            {isMultipleEvents && (
              <Box>
                <Typography variant='subtitle2' className={classes.subtitle}>
                  Events
                </Typography>

                {toScan.map((eventScan, index) => (
                  <Box className={classes.eventList} key={eventScan.id}>
                    <Typography variant='body1'>{eventScan.title}{index !== toScan.length - 1 ? ',' : ''}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <OnlineModal
            open={onlineModal}
            description='Going to offline mode implies to stop synchronizing data on real time. Once you’re done scanning you’ll have to go back online to synchronize all scans'
          />
          <Box display='flex-wrap' flexDirection='column' padding={2}>
            {pendingTicketsCount > 0 && (
              <Box marginBottom={1}>
                <Typography variant='subtitle1' color='error'>
                  There are errors to check in the synchronization
                </Typography>
              </Box>
            )}
            <OfflineButtons ids={toScan.map((event) => event.id)} />
          </Box>
        </>
      )}
    </Box>
  );
};
